<template>
  <header class="py-3.5 flex justify-center m-auto bg-sky-100">
    <img alt="Running logo" class="w-20" src="./../assets/runningstatus.png">
  </header>
</template>

<script>
export default {
  name: 'TheHeader',
  props: {
    msg: String
  }
}
</script>
